const App = {
  InitLazyLoad: () => {
    return new LazyLoad({
      elements_selector: ".lazyload",
    });
  },
};

function height(el) {
  var height = 0;
  $(el).each(function () {
    var thisHeight = $(this).height();
    if (thisHeight > height) {
      height = thisHeight;
    }
    setTimeout(() => {
      $(el).height(height);
    }, 100);
  });
}
let header = {
  scrollActive: function () {
    let height = $("header").height();
    if ($(window).scrollTop() > height) {
      $("header").addClass("active");
    } else {
      $("header").removeClass("active");
    }
  },
};

function Headers() {
  var e = 0;
  $(window).scroll(function () {
    var i = $(window).scrollTop();
    $("body header").toggleClass("hide", i > e),
      (e = $(window).scrollTop()),
      i > 0
        ? $("header").addClass("is-scrolled")
        : $("header").removeClass("is-scrolled"),
      $(window).scrollTop() >= $(document).height() - $(window).height();
  });
}

function setBackgroundElement() {
  $("[setBackground]").each(function () {
    var background = $(this).attr("setBackground");
    $(this).css({
      "background-image": "url(" + background + ")",
      "background-size": "cover",
      "background-position": "center center",
    });
  });
  $("[setBackgroundRepeat]").each(function () {
    var background = $(this).attr("setBackgroundRepeat");
    $(this).css({
      "background-image": "url(" + background + ")",
      "background-repeat": "repeat",
    });
  });
}

function mappingMenu() {
  const mainSearchMobileMapping = new MappingListener({
    selector: ".main-search",
    mobileWrapper: ".mobile-menu-wrap",
    mobileMethod: "appendTo",
    desktopWrapper: ".top-wrap",
    desktopMethod: "prependTo",
    breakpoint: 1025,
  }).watch();

  const mainMenuMobileMapping = new MappingListener({
    selector: ".main-menu",
    mobileWrapper: ".mobile-menu-wrap",
    mobileMethod: "appendTo",
    desktopWrapper: ".bottom-wrap",
    desktopMethod: "prependTo",
    breakpoint: 1025,
  }).watch();

  const mainLanguageMobileMapping = new MappingListener({
    selector: ".main-language",
    mobileWrapper: ".mobile-menu-wrap",
    mobileMethod: "appendTo",
    desktopWrapper: ".main-user",
    desktopMethod: "insertBefore",
    breakpoint: 1025,
  }).watch();
}

function menuMobile() {
  $(".menu-toggle").click(function (e) {
    $(".mobile-menu").fadeIn().addClass("open");
  });

  $(".mobile-menu").click(function (e) {
    if (e.target == this) $(".mobile-menu").fadeOut().removeClass("open");
  });
}

function fancyboxModal() {
  $(".btn-open-newsfancybox").click(function (e) {
    console.log(1);
    e.preventDefault();
    $.fancybox.open({
      src: "#modal",
      type: "inline",
      opts: {
        afterShow: function (instance, current) {
          $(".fancybox-is-open").appendTo("main");
        },
      },
    });
    return false;
  });
}

function languageToggle() {
  $("header .main-price .dropdown").on("click", function () {
    $("header .main-price .dropdown .dropdown-item").slideToggle();
  });
}

function InitSlider() {
  var HomeBanner = new Swiper(".home-banner .swiper-container", {
    slidesPerview: 1,
    speed: 1000,
    loop: true,
    autoplay: {
      delay: 4000,
    },
    pagination: {
      el: ".home-banner .swiper-pagination",
      clickable: true,
    },
  });
  var PageBanner = new Swiper(".home-1 .swiper-container", {
    slidesPerview: 1,
    speed: 1000,
    loop: true,
    autoplay: {
      delay: 4000,
    },
    navigation: {
      nextEl: ".home-1 .button-next",
      prevEl: ".home-1 .button-prev",
    },
    slidesPerView: 5,
    spaceBetween: 30,
    breakpoints: {
      576: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      1025: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
    },
  });
  var SPM = new Swiper(".home-2 .swiper-container", {
    speed: 1000,
    // autoplay: {
    //     delay: 4000
    // },
    slidesPerColumnFill: "row",
    navigation: {
      nextEl: ".home-2 .button-next",
      prevEl: ".home-2 .button-prev",
    },
    slidesPerView: 4,
    slidesPerColumn: 2,
    breakpoints: {
        576: {
            slidesPerView: 1,
            slidesPerColumn: 1
        },
        768: {
            slidesPerView: 2,
            slidesPerColumn: 1
        },
        1025: {
            slidesPerView: 2,
            slidesPerColumn: 1
        },
        1200: {
            slidesPerView: 3,
            slidesPerColumn: 1
        }
    }
  });


  $(".product-slider").each(function (index) {
    var $this = $(this);
    $this.addClass("product-slider-" + index);
    $this.find(".button-prev").addClass("btn-prev-" + index);
    $this.find(".button-next").addClass("btn-next-" + index);

    var productSlierSwiper = new Swiper(
      ".product-slider-" + index + " .swiper-container",
      {
        speed: 1000,
        loop: true,
        autoplay: {
          delay: 4000,
        },
        navigation: {
          prevEl: ".btn-prev-" + index,
          nextEl: ".btn-next-" + index,
        },
        slidesPerView: 4,
        breakpoints: {
            576: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            1025: {
                slidesPerView: 2
            },
            1200: {
                slidesPerView: 3
            }
        }
      }
    );
  });

  var TT = new Swiper(".home-44 .swiper-container", {
    speed: 1000,
    loop: true,
    spaceBetween: 30,
    autoplay: {
      delay: 4000,
    },
    navigation: {
      nextEl: ".home-44 .button-next",
      prevEl: ".home-44 .button-prev",
    },
  });
  var Video = new Swiper(".home-55 .swiper-container", {
    speed: 1000,
    loop: true,
    spaceBetween: 30,
    autoplay: {
      delay: 4000,
    },
    navigation: {
      nextEl: ".home-55 .button-next",
      prevEl: ".home-55 .button-prev",
    },
  });

  var ImageThumb = new Swiper(
    ".product-detail .thumbnail-slider .swiper-container",
    {
      spaceBetween: 10,
      slidesPerView: 4,
      direction: 'vertical',
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: ".product-detail .thumbnail-slider .button-next",
        prevEl: ".product-detail .thumbnail-slider .button-prev",
      },
      breakpoints: {
        576: {
          direction: 'horizontal',
          slidesPerView: 3
        }
      }
    }
  );

  var ImageTop = new Swiper(".product-detail .image-slider .swiper-container", {
    spaceBetween: 10,
    thumbs: {
      swiper: ImageThumb,
    }
  });
  var ViewedProduct = new Swiper(
    ".product-detail .viewed-products-wrapper .swiper-container",
    {
      slidesPerView: 4,
      speed: 1000,
      loop: true,
      spaceBetween: 30,
      navigation: {
        nextEl: ".product-detail .viewed-products-wrapper .swiper-next",
        prevEl: ".product-detail .viewed-products-wrapper .swiper-prev",
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1025: {
          slidesPerView: 3,
        },
        1430: {
          slidesPerView: 4,
        },
      },
    }
  );
  var ProductReviews = new Swiper(
    ".product-detail .product-reviews-wrapper .swiper-container",
    {
      slidesPerView: 4,
      speed: 1000,
      loop: true,
      spaceBetween: 30,
      navigation: {
        nextEl: ".product-detail .product-reviews-wrapper .swiper-next",
        prevEl: ".product-detail .product-reviews-wrapper .swiper-prev",
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1025: {
          slidesPerView: 3,
        },
        1430: {
          slidesPerView: 4,
        },
      },
    }
  );
  var RelatedProducts = new Swiper(
    ".related-products-wrapper .swiper-container",
    {
      slidesPerView: 4,
      loop: true,
      speed: 1000,
      spaceBetween: 30,
      navigation: {
        nextEl: ".related-products-wrapper .swiper-next",
        prevEl: ".related-products-wrapper .swiper-prev",
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1025: {
          slidesPerView: 3,
        },
        1430: {
          slidesPerView: 4,
        },
      },
    }
  );

  var about2Swiper = new Swiper('.about-2 .swiper-container', {
    navigation: {
      nextEl: ".about-2 .button-next",
      prevEl: ".about-2 .button-prev",
    },
  })

  var brandSwiper = new Swiper('.brand-slider .swiper-container', {
    slidesPerView: 4,
    slidesPerColumn: 2,
    navigation: {
      nextEl: ".brand-slider .button-next",
      prevEl: ".brand-slider .button-prev",
    },
    breakpoints: {
      576: {
        slidesPerColumn: 1,
        slidesPerView: 2,
      },
      768: {
        slidesPerColumn: 1,
        slidesPerView: 3,
      }
    }
  })
}

function backToTop() {
  $(window).scroll(function (event) {
    var scrollTop = $(window).scrollTop();
    if (scrollTop >= 900) {
      $(".backtotop").addClass("active");
    } else {
      $(".backtotop").removeClass("active");
    }
  });
  $(".backtotop").click(function () {
    $("html, body").animate(
      {
        scrollTop: 0,
      },
      1000
    );
  });
}

function ProductFilter() {
  $(".product-list .product-filter-button").on("click", function () {
    $(".product-list .product-filter-wrapper").addClass("active");
  });
  $(".product-list .product-filter-close").on("click", function () {
    $(".product-list .product-filter-wrapper").removeClass("active");
  });
  $(
    ".product-list .product-list-wrapper .heading-wrapper .product-display a"
  ).on("click", function () {
    var e = $(this).attr("display");
    $(
      ".product-list .product-list-wrapper .heading-wrapper .product-display a"
    ).removeClass("active");
    $(this).addClass("active");
    if (e == 2) $(".item-product").parent().addClass("active");
    else $(".item-product").parent().removeClass("active");
  });
}

function ProductQuantity() {
  $(".btn-minus").on("click", function () {
    var curVal = Number($(this).siblings("input").val()) - 1;

    if (curVal <= 1) {
      curVal = 1;
    }

    $(this).siblings("input").val(curVal);
    $(this).siblings("input").attr("value", curVal);
    $(this).siblings("input").trigger("change");
  });
  $(".btn-plus").on("click", function () {
    var curVal = Number($(this).siblings("input").val()) + 1;
    $(this).siblings("input").val(curVal);
    $(this).siblings("input").attr("value", curVal);
    $(this).siblings("input").trigger("change");
  });
}

function ProductReviewTab() {
  $(".product-detail .product-detail-wrapper .heading").on(
    "click",
    function () {
      var tabId = $(this).attr("data-tab");
      $(".product-detail .product-detail-wrapper .heading").removeClass(
        "active"
      );
      $(this).addClass("active");
      $(".product-detail .product-detail-wrapper .content").removeClass(
        "active"
      );
      $(
        ".product-detail .product-detail-wrapper .content[data-content=".concat(
          tabId,
          "]"
        )
      ).addClass("active");
    }
  );
  $(".product-detail .product-detail-wrapper .heading:first-child").trigger(
    "click"
  );
}
function shareSocial() {
  $("#shareNewsDeail").jsSocials({
    showLabel: false,
    showCount: false,
    shares: [
      {
        share: "facebook",
        logo: "fa fa-facebook",
      },
      {
        share: "messenger",
        logo: "./img/messenger.png",
      },
      {
        share: "twitter",
        logo: "fa fa-twitter",
      },
      {
        share: "line",
        logo: "./img/line.png",
      },
    ],
  });
}

function clickPrint() {
  $(".everest-truyen-thong-ct .main-date-social .share .print").click(
    function () {
      window.print();
    }
  );
}

function tabInit() { 
  $('.tab-list ul a').click(function (e) {
    e.preventDefault();
    var tabIndex = $(this).attr('href').substr(1);
    var tabId = '#' + $(this).closest('.tab-list').attr('id');
    $('.tab-list ul a').removeClass('active');
    $(this).addClass('active');
    
    $('.tab-container' + tabId).find('.tab-content').removeClass('show');
    $('.tab-container' + tabId).find('#' + tabIndex).addClass('show');
    
    var awardSwiper = new Swiper('#' + tabIndex + ' .award-slider .swiper-container', {
      slidesPerView: 4,
      spaceBetween: 30,
      navigation: {
        prevEl: '#' + tabIndex + ' .award-slider .button-prev',
        nextEl: '#' + tabIndex + ' .award-slider .button-next'
      },
      breakpoints: {
        576: {
          slidesPerView: 1
        },
        768: {
          slidesPerView: 2
        },
        1025: {
          slidesPerView: 3
        }
      }
    });
  });

  $('.tab-list ul a').eq(0).click()
 }



$(document).on("scroll", function () {
  header.scrollActive();
});
$(document).ready(function () {
  // lazyload
  App.InitLazyLoad();
  // menu
  Headers();
  mappingMenu();
  menuMobile();
  languageToggle();

  // slide
  InitSlider();

  // set background
  setBackgroundElement();

  // fancyboxModal
  fancyboxModal();

  // backtotop
  backToTop();

  // product
  ProductFilter();
  ProductQuantity();
  ProductReviewTab();

  // height
  height(".everest-tin-tuc .main-tin-tuc .item-child figcaption");

  // news
  shareSocial();
  clickPrint();
  filterToggle();

  tabInit()

  $('.view-more').click(function(e){
    $(this).toggleClass('expanded');
    $(this).siblings().toggleClass('show')
  })
});

function filterToggle() {
  let title = $(".product-filter-wrapper .item .heading");
  title.on("click", function () {
    $(this).next().slideToggle();
  });
}
